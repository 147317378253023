import styled from 'styled-components';
import { rgba } from 'polished';

import { mq } from 'theme/media';

export const StyledReviewsSlider = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.spacing(8, 0)};
  margin-top: ${({ theme }) => theme.spacing(3)};
  width: 100%;
  height: auto;
  z-index: 1;
  background-color: ${({ theme }) => rgba(theme.colors.violetGray, 0.2)};

  ${mq.medium`
    padding: ${({ theme }) => theme.spacing(5, 0)};
  `}
`;

export const StyledReviewsRow = styled.div`
  flex: 50%;
  margin: ${({ theme }) => theme.spacing(0, -4, 13)};

  ${mq.large`
    margin: ${({ theme }) => theme.spacing(0, -4, 18)};
  `}

  ${mq.xsLarge`
    margin: ${({ theme }) => theme.spacing(0, 0, 18)};
  `}
`;

export const StyledClutchLogoWrapper = styled.div`
  display: none;

  ${mq.medium`
    position: absolute;
    top: 50%;
    left: 15%;
    display: block;
    width: 247px;
    height: auto;
    margin-top: ${({ theme }) => theme.spacing(-18)};
  `}
`;
export const StyledClutchRatingText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.medium`
    align-items: stretch;
  `}
`;

export const StyledClutchRating = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.blackRock};
  padding: ${({ theme }) => theme.spacing(0, 4)};

  ${mq.medium`
    justify-content: flex-end;
    margin-bottom: ${({ theme }) => theme.spacing(-2)};
    padding: ${({ theme }) => theme.spacing(0, 9)};
  `}
`;

export const StyledSlider = styled.div`
  position: relative;
  height: 250px;
  overflow: hidden;
`;

export const StyledParallelogramsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(7)};

  ${mq.medium`
    margin-top: 0;
  `}
`;
export const StyledBulletsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(5)};
`;

export const StyledTitleWrapper = styled.div`
  text-align: center;
  margin: ${({ theme }) => theme.spacing(3, 0, 6)};
`;
