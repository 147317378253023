import React, { FC, useState } from 'react';
import Image from '@next/image';
import Typography from 'components/atoms/Typography/Typography';

import { Slider } from 'components/Slider/Slider';
import { Pagination } from 'components/Slider/Pagination';
import { ReviewProps, ReviewsSlide } from './ReviewsSlide/ReviewsSlide';
import { Parallelograms } from './Parallelograms/Parallelograms';
import {
  StyledReviewsSlider,
  StyledReviewsRow,
  StyledClutchLogoWrapper,
  StyledClutchRatingText,
  StyledClutchRating,
  StyledSlider,
  StyledParallelogramsContainer,
  StyledBulletsWrapper,
  StyledTitleWrapper
} from './Reviews.styles';

export type ReviewsProps = {
  id?: string;
  sectionTitle?: string;
  title?: string;
  rating?: number;
  reviews: Array<ReviewProps>;
  buttonCopy?: string;
};

const Reviews: FC<ReviewsProps> = ({ title, rating, reviews, buttonCopy, sectionTitle }) => {
  const [[currentItem, direction], setCurrentItem] = useState([0, 0]);

  const setItem = (newItem: number, newDirection: number) => {
    if (!newDirection) {
      newDirection = newItem - currentItem;
    }
    setCurrentItem([newItem, newDirection]);
  };

  return (
    <>
      <StyledReviewsRow>
        {sectionTitle && (
          <StyledTitleWrapper>
            <Typography variant="h2">{sectionTitle}</Typography>
          </StyledTitleWrapper>
        )}
        <StyledReviewsSlider>
          {title && rating && (
            <StyledClutchRating>
              <StyledClutchRatingText>
                <Typography variant="body2" weight="800">
                  {title}
                </Typography>
                <Typography variant="h3" weight="800">
                  {rating}
                </Typography>
              </StyledClutchRatingText>
            </StyledClutchRating>
          )}
          <StyledParallelogramsContainer>
            <Parallelograms />
          </StyledParallelogramsContainer>
          {rating && (
            <StyledClutchLogoWrapper>
              <Image src="/img/logos/clutch.png" alt="Logo of Clutch website" width={248} height={280} />
            </StyledClutchLogoWrapper>
          )}
          <StyledSlider>
            {reviews.length > 1 ? (
              <Slider numberOfItems={reviews.length} currentItem={currentItem} direction={direction} setItem={setItem}>
                <ReviewsSlide {...reviews[currentItem]} buttonCopy={buttonCopy} />
              </Slider>
            ) : (
              <ReviewsSlide {...reviews[0]} buttonCopy={buttonCopy} />
            )}
          </StyledSlider>
        </StyledReviewsSlider>
        <StyledBulletsWrapper>
          {reviews.length > 1 && (
            <Pagination items={reviews} currentItem={currentItem} setItem={setItem} marginTop="30px" />
          )}
        </StyledBulletsWrapper>
      </StyledReviewsRow>
    </>
  );
};

export default Reviews;
