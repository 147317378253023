import React, { FC } from 'react';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';

import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import Typography from 'components/atoms/Typography/Typography';
import { Marked } from 'components/Marked/Marked';

import useMedia from 'hooks/useMedia';

import {
  StyledReviewContainer,
  StyledReviewPersonWrapper,
  StyledReviewWrapper,
  StyledCheckClutchReviewsWrapper
} from './ReviewsSlide.styles';

export type ReviewProps = {
  id?: string;
  author: MDXRemoteSerializeResult;
  position?: MDXRemoteSerializeResult;
  company?: string;
  review: MDXRemoteSerializeResult;
  href?: string;
  buttonCopy?: string;
};

export const ReviewsSlide: FC<ReviewProps> = ({ review, author, position, company, href, buttonCopy }) => {
  const { isMobile } = useMedia();

  return (
    <StyledReviewContainer>
      <StyledReviewWrapper>
        <Typography variant="h4" weight="300">
          <Marked>{review}</Marked>
        </Typography>
        <StyledReviewPersonWrapper>
          <Typography variant="body2" weight="300" renderAs="div">
            {position && (
              <>
                <Marked>{position}</Marked>{' '}
              </>
            )}
            {company ? <strong>{company} </strong> : ''}
            <Marked>{author}</Marked>
          </Typography>
        </StyledReviewPersonWrapper>
        {!isMobile && href && (
          <StyledCheckClutchReviewsWrapper>
            <LinkedButton href={href} variant="tertiary-white">
              {buttonCopy || 'Review'}
            </LinkedButton>
          </StyledCheckClutchReviewsWrapper>
        )}
      </StyledReviewWrapper>
    </StyledReviewContainer>
  );
};
