import styled from 'styled-components';

import { mq } from 'theme/media';

export const StyledReviewContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.spacing(1)};
  max-width: 90%;
  margin: 0 auto;

  ${mq.medium`
    max-width: 80%;
  `}
`;

export const StyledReviewWrapper = styled.div`
  color: ${({ theme }) => theme.colors.blackRock};
  text-align: center;

  ${mq.medium`
    max-width: 600px;
  `}
`;

export const StyledReviewPersonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const StyledCheckClutchReviewsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;
